import { Component } from 'react';
import styled from '../../../theme';

const StyledOnboardCustomer = styled.div`
`;

class OnboardCustomer extends Component {
  render() {
    return (
      <StyledOnboardCustomer>
        OnboardCustomer
      </ StyledOnboardCustomer>
    );
  }
}

export default OnboardCustomer;
