import styled from '../../theme';
import Link from '../../_componentLibrary/Link';
import primaryTheme from '../../theme/primaryTheme';

const Logo = styled(Link)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 1rem auto;
  width: 95px;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: auto; 
  width: auto;
  padding: 1rem 2vw;
  background-color: ${primaryTheme.colors.secondary};
  z-index: 21;

  @media (max-width: 1024px) {
    padding: 1rem 10vw;
  }
`;

const MenuContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 1rem auto;
  width: auto;
  position: relative;
`;

const MenuItem = styled.li`
  font-size: 1rem;
  white-space: nowrap;
  color: ${primaryTheme.colors.primary};
`;

const MenuLink = styled(Link)`
  display: inline;
  padding: 1.5rem 1rem;
`;

export { 
  HeaderContainer, 
  Logo,
  MenuContainer,
  MenuItem,
  MenuLink
}
