import styled from 'theme';

const MenuScreenWrapper = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  height: 0;
  width: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 19;
`;

const MenuScreen = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
`;


export { 
  MenuScreen,
  MenuScreenWrapper
};
