import { AuthProvider } from 'contexts/Auth/authProvider';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Landing from 'components/_pages/Landing';
import CustomersList from 'components/_pages/CustomersList';
import Insights from 'components/_pages/Insights';
import Invoices from 'components/_pages/Invoices';
import OnboardCustomer from 'components/_pages/OnboardCustomer';
import Schedule from 'components/_pages/Schedule';
import Payment from 'components/_pages/Payment';
import Settings from 'components/_pages/Settings';
import NotFound from 'components/_pages/NotFound';
import Modal from 'components/Modal';
import awsconfig from '../../amplifyconfiguration.json';
import { Amplify } from 'aws-amplify';

import { MainContainer } from  './styles.js';
import '@aws-amplify/ui-react/styles.css';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

Amplify.configure(awsconfig);

const withAuthenticatorOptions = {
  hideSignUp: false
}

function Main() {
  const menuItems = [
    {
      name: 'Onboard Customer',
      address: '/onboard-customer',
      identifier: 'onboard-customer',
    },
    {
      name: 'Schedule',
      address: '/schedule',
      identifier: 'schedule',
    },
    {
      name: 'Invoices',
      address: '/invoices',
      identifier: 'invoices',
    },
    {
      name: 'Customers',
      address: '/customers',
      identifier: 'customers',
    },
    {
      name: 'Insights',
      address: '/insights',
      identifier: 'insights',
    },
    {
      name: 'Settings',
      address: '/settings',
      identifier: 'settings',
    }
  ];

  return (
    <AuthProvider>
      <Router>
        <Header />
        <Modal items={menuItems} />
        <MainContainer>
          <Routes>
            {/* utility*/}
            {/* <Route path="/signup" component={AddUser} exact={true} /> */}
            <Route path='/' element={<Landing />} />
            <Route path='*' element={<NotFound />} />
            <Route path='/onboard-customer' element={<OnboardCustomer />} />
            <Route path='/schedule' element={<Schedule />} />
            <Route path='/invoices' element={<Invoices />} />
            <Route path='/customers' element={<CustomersList />} />
            <Route path='/insights' element={<Insights />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/payment' element={<Payment />} />
          </Routes>
        </MainContainer>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default withAuthenticator(Main, withAuthenticatorOptions);
