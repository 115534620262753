import { Component } from 'react';
import styled from '../../../theme';

const StyledInvoices = styled.div`
`;

class Invoices extends Component {
  render() {
    return (
      <StyledInvoices>
        Invoices
      </ StyledInvoices>
    );
  }
}

export default Invoices;


