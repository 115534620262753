import { AuthContext } from './authContext';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const AuthProvider = ({ children }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <AuthContext.Provider value={{ user, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
