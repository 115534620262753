import styled from '../../../theme';
import { useAuthenticator } from '@aws-amplify/ui-react';

const StyledSettings = styled.div`
`;

const Settings = () => {
  const { signOut } = useAuthenticator()

  return (
    <StyledSettings>
      Settings
      <button onClick={() => signOut()}>Log Out</button>
    </ StyledSettings>
  );
}

export default Settings;
