import { useContext } from 'react';
import { DisplayContext } from 'contexts/Display/displayContext';
import {
  MenuContainer,
  MenuItem,
  MenuLinkWrapper,
  MenuLink
} from './styles.js';

const MenuBar = ({items}) => {
  const displayContext = useContext(DisplayContext);

  const MenuContents = ({items}) => {
    return items.map((item) => (
      <MenuLinkWrapper key={item?.identifier} linkstyle='menuList' address={item?.address}>
        <MenuLink
          key={item?.identifier}
          linkstyle='menuList'
          address={item?.address}
          onClick={item.onClick ? item.onClick : () => displayContext.setShowModal(!displayContext.showModal)}
        >
          <MenuItem>{item?.name}</MenuItem>
        </MenuLink>
      </MenuLinkWrapper>
    ))
  };

  return (
    <>
      <MenuContainer>
        <MenuContents items={items} />
      </MenuContainer>
    </>
  );
}

export default MenuBar;
