import styled, {primaryTheme} from 'theme';
import Link from '_componentLibrary/Link';

const FooterLink = styled(Link)`
  padding: 1rem 1rem;
  border: 2px solid ${primaryTheme.colors.primary};
  border-radius: 4px;
  flex-basis: 100%;
`;

const IconWrapper = styled.div`
  flex; 1;
  width: 22.5%;
  text-align: center;
`;

const StyledFooter = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  background-color: ${primaryTheme.colors.white};
  border-top: 2px solid ${primaryTheme.colors.primary};
  color: ${primaryTheme.colors.tertiary};
  padding: 1rem 5%;
  width: 100vw;
  z-index: 1;
  position: fixed;
  bottom: 0;
  @media (min-width: 1350px) {
    display: none
  }
`;

export {
  FooterLink,
  IconWrapper,
  StyledFooter
};
