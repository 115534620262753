import { Component } from 'react';
import styled from '../../../theme';

const StyledSchedule = styled.div`
`;

class Schedule extends Component {
  render() {
    return (
      <StyledSchedule>
        Schedule Edited
      </ StyledSchedule>
    );
  }
}

export default Schedule;

