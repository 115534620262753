import React, { useState, useEffect, useContext } from 'react';
import SvgIcon from 'assets/svgs';
import { primaryTheme } from 'theme';
import { DisplayContext } from 'contexts/Display/displayContext';
import {
  HeaderContainer,
  Logo,
  MenuContainer,
} from './styles.js';
import { AuthContext } from 'contexts/Auth/authContext.js';

const Header = () => {
  const [width, setWidth] = useState(1280);

  useEffect(() => {
    setWidth(window.innerWidth)
  }, []);

  const mobile = width < 724;

  const context = useContext(AuthContext);

  const displayContext = useContext(DisplayContext);

  return (
    <>
      <HeaderContainer>
        <Logo linkstyle='headerLogo' target='_self' address='/'>
          <SvgIcon
            name={mobile ? 'mobileLogo' : 'mainLogo'}
            height={30}
            width={mobile ? 167.71 : 167.71}
            viewBox={`0 0 167.1 30`}
            style={{cursor: 'pointer'}}
            fill={primaryTheme.colors.white}
            altFill={primaryTheme.colors.accent} />
        </Logo>
        <div>
          email: {context?.user?.signInDetails?.loginId}
        </div>
        <MenuContainer onClick={() => displayContext.setShowModal(!displayContext.showModal)}>
          { displayContext.showModal ?
            (
              <SvgIcon
                name='close'
                height={30}
                width={30}
                viewBox={`0 0 30 30`}
                style={{cursor: 'pointer'}}
                fill={primaryTheme.colors.white}
                altFill={primaryTheme.colors.accent} />
            )
          : (
              <SvgIcon
                name='bars'
                height={28}
                width={37.53}
                viewBox={`0 0 37.53 28`}
                style={{cursor: 'pointer'}}
                fill={primaryTheme.colors.white}
                altFill={primaryTheme.colors.accent} />
          )
          }
        </MenuContainer>
      </HeaderContainer>
    </>
  );
}

export default Header;
