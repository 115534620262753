import { Component } from 'react';
import styled from '../../../theme';

const StyledCustomerList = styled.div`
`;

class CustomerList extends Component {
  render() {
    return (
      <StyledCustomerList>
        CustomerList
      </ StyledCustomerList>
    );
  }
}

export default CustomerList;
