import React from 'react';
import {
  LandingContainer,
} from './styles.js';

const Landing = () => {
  return (
    <LandingContainer>
      Coming Soon
    </LandingContainer>
  );
}

export default Landing;

