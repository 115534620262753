import { Component } from 'react';
import styled from '../../../theme';

const StyledPayment = styled.div`
`;

class Payment extends Component {
  render() {
    return (
      <StyledPayment>
        Payment
      </ StyledPayment>
    );
  }
}

export default Payment;


