import { useState } from 'react';
import { DisplayContext } from './displayContext';

export const DisplayProvider = ({ children }) => {
  const [ showModal, setShowModal ] = useState(false); 

  return (
    <DisplayContext.Provider value={{ showModal: showModal, setShowModal: setShowModal }}>
      {children}
    </DisplayContext.Provider>
  );
};


