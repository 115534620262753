import { Component } from 'react';
import styled from '../../../theme';

const StyledInsights = styled.div`
`;

class Insights extends Component {
  render() {
    return (
      <StyledInsights>
        Insights
      </ StyledInsights>
    );
  }
}

export default Insights;
