import styled, {primaryTheme} from 'theme';
import Link from '_componentLibrary/Link';

const MenuScreen = styled.div`
  z-index: 19; 
  height: 100vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.4);
  padding: 0;
`;

const MenuContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  flex-wrap: wrap;
  z-index: 20;
  align-items: center;
  text-align: center;
  list-style: none;
  color: ${primaryTheme.colors.primary};
  background-color: ${primaryTheme.colors.white};
  border-top: 2px solid ${primaryTheme.colors.primary};
  padding: 1.5rem 2rem;
`;

const MenuItem = styled.div`
  font-size: 1rem;
  white-space: nowrap;
  color: ${primaryTheme.colors.primary};
  padding: 1rem auto;
`;

const MenuLinkWrapper = styled.li`
  flex: 1;
  padding: 1rem 0;
  flex-basis: 100%;
  display: flex;
`;

const MenuLink = styled(Link)`
  padding: 1rem 1rem;
  border: 2px solid ${primaryTheme.colors.primary};
  border-radius: 4px;
  flex-basis: 100%;
`;

export { 
  MenuScreen,
  MenuContainer,
  MenuItem,
  MenuLink,
  MenuLinkWrapper
}
