import { useContext } from 'react';
import { DisplayContext } from 'contexts/Display/displayContext';
import MenuBar from './MenuBar';
import {
  MenuScreen,
  MenuScreenWrapper
} from  './styles.js';

const Modal = ({
  rawContent = false,
  items = [],
  children,
  ...rest
}) => {
  const displayContext = useContext(DisplayContext);

  if (rawContent && !!displayContext.showModal) {
    return (
      <MenuBar>
        {children}
      </MenuBar>
    )
  } else if (!!displayContext.showModal) {
    return (
      <>
        <MenuBar items={items}/>
        <MenuScreenWrapper onClick={() => displayContext.setShowModal(!displayContext.showModal)}>
          <MenuScreen />
        </MenuScreenWrapper>
      </>
    )
  } else {
    return null
  }
}

export default Modal;
