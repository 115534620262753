import { IconWrapper, StyledFooter } from './styles';
import SvgIcon from 'assets/svgs';
import { primaryTheme } from 'theme';
import { FooterLink } from './styles.js';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <StyledFooter className='footer'>
      <IconWrapper>
        <FooterLink linkstyle='unstyled' address='/onboard-customer'>
          <SvgIcon
            name={'addPerson'}
            height={28}
            width={37.53}
            viewBox={`0 0 37.53 28`}
            style={{cursor: 'pointer'}}
            fill={primaryTheme.colors.primary}
            altFill={primaryTheme.colors.accent} />
        </FooterLink>
      </IconWrapper>
      <IconWrapper>
        <FooterLink linkstyle='unstyled' address='/settings'>
          <SvgIcon
            name={'settings'}
            height={30}
            width={30}
            viewBox={`0 0 30 30`}
            style={{cursor: 'pointer'}}
            fill={primaryTheme.colors.primary}
            altFill={primaryTheme.colors.accent} />
        </FooterLink>
      </IconWrapper>
      <IconWrapper>
        <FooterLink linkstyle='unstyled' address='/payment'>
          <SvgIcon
            name={'pay'}
            height={30}
            width={17.81}
            viewBox={`0 0 17.81 30`}
            style={{cursor: 'pointer'}}
            fill={primaryTheme.colors.primary}
            altFill={primaryTheme.colors.accent} />
        </FooterLink>
      </IconWrapper>
      
      <IconWrapper>
        { location?.pathname !== '/'
          ? (
            <FooterLink linkstyle='unstyled' onClick={() => navigate(-1)}>
              <SvgIcon
                name={'return'}
                height={28}
                width={28}
                viewBox={`0 0 28 28`}
                style={{cursor: 'pointer'}}
                fill={primaryTheme.colors.primary}
                altFill={primaryTheme.colors.accent} />
            </FooterLink>
            )
          : (
            <FooterLink linkstyle='unstyled' address='/'>
              <SvgIcon
                name={'return'}
                height={28}
                width={28}
                viewBox={`0 0 28 28`}
                style={{cursor: 'pointer'}}
                fill={primaryTheme.colors.primary}
                altFill={primaryTheme.colors.accent} />
            </FooterLink>
          ) 
        } 
      </IconWrapper>
    </ StyledFooter>
  );
}

export default Footer;
